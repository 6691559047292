import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const CommissionGraph = (obj) => {


  return (
    <div className="stroke fullMargin insetEffect" style={{ padding: 0, margin: 0 }}>

    </div>
  )
}

export default CommissionGraph;